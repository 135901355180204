import React from 'react';
import { useStateDispatchContext, useStateContext } from '../../state';
import { SelectWrapperDiv, PlayerSection } from './styles';
import { playerName, playerPosition, playerImage } from '../../helpers';

export const PlayerSelect = () => {
  const { activePosition, players } = useStateContext();
  const { dispatch } = useStateDispatchContext();

  const handlePlayerSelect = (player) => () => {
    dispatch({ type: 'SET_PLAYER_TO_ACTIVE_POSITION', player });
  }

  return (
    <SelectWrapperDiv show={!!activePosition}>
      {players.map((player, i) => (
        <PlayerSection
          onClick={handlePlayerSelect(player)}
          hasImage={player && !!player.PlayerPicture.PictureUrl}
          index={i}
          show={!!activePosition}
          key={player.IdPlayer + playerName(player)}
        >
          <h2>{playerName(player)}</h2>
          <p>{playerPosition(player)}</p>
          <img src={playerImage(player)}  alt="player" />
        </PlayerSection>
      ))}
    </SelectWrapperDiv>
  );
}
