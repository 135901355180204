import React from 'react'
import { Formation } from './components/formation';
import { PlayerSelect } from './components/player-select';
import { StartModal } from './components/start-modal';
import { StateProvider } from './state';
import { AppDiv, GlobalStyles, Wrapper } from './styles';

function App() {

  return (
    <AppDiv>
      <GlobalStyles />
      <div id="gradientBg"></div>
      <StateProvider>
        <Wrapper>
          <StartModal />
          <PlayerSelect />
          <Formation />
        </Wrapper>
      </StateProvider>
    </AppDiv>
  );
}

export default App;
