import React from 'react';
import { teamName, teamFlag } from '../../helpers';
import { useStateContext} from '../../state';
import Lockup from '../../assets/lockup-white.png';
import { StyledHeader } from './styles';

export const Header = () => {
  const { selectedTeam } = useStateContext();

  return (
  <>
    { (selectedTeam && Object.keys(selectedTeam).length) &&
      <StyledHeader>
        <img src={Lockup} alt="FIFA World Cup Logo" /> 
        <img src={teamFlag(selectedTeam)} alt={`${teamName(selectedTeam)} Flag`} />
      </StyledHeader>
    }
   </>
  )
}
